
.owncontainer_m {
    margin: 0 auto;
    min-height: 212.7px;
  }


.lichtcontainer_m {
  margin: 0 auto;
  min-height: 412.7px;
}



@media (max-width: 800px) { 
  .lichtcontainer_m {
      width: 100%;
      overflow-y: scroll;
      min-height: 0px;
      max-height: 200px;
      margin: 0 auto -20%;
  }
}

@media (max-width: 700px) { 
  .lichtcontainer_m {
      width: 100%;
      overflow-y: scroll;
      min-height: 0px;
      max-height: 100px;
      margin: 0 auto -30%;
  }
}

@media (max-width: 600px) { 
    .lichtcontainer_m {
        width: 100%;
        /* overflow-y: scroll; */

        min-height: 650px;
        margin: 0 auto;
    }
  }