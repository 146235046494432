/* 60& Iphone se */
.backgroundimg_m {
    background-image: url('../../../Bilder/lichtbild2.jpg');
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat;
    height: 92%;
    /* margin-top: -4%; */
  }

  .backgroundimg_kontakt_m {
    background-image: url('../../../Bilder/lichtbild2.jpg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    height: 63vh;
    /* margin-top: 1.4rem; */
    /* padding-top: 5rem; */
  }


  .background_container {
    height: 84.5vh;
  }

   .background_container_kontakt {
    /* height: 31.55rem; */
    /* margin-top: 13%;
    margin-bottom: -13%; */
    padding-top: 4.8rem;
  }
  

