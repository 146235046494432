 .header {
    padding: 1.1%;
    background: grey;
    font-size: 30px;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_white {
  background: grey;
  font-size: 30px;
  width: 100%;
  z-index: 3;
  position: fixed;
  left: 0;
  padding: 1.1%;
}

.footerImage_m {
    margin: auto 25px 4px;
    width: 2.4rem;
    height: 2.4rem;
}


div > .header {
    z-index: 2;
    background-color: grey;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  
  .overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color:grey;
    overflow-y: hidden;
    transition: 0.5s;
  }
  
  .overlay-content {
    position: relative;
    top: 30%;
    /* width: 100%;
    height: 75%;
    text-align: center; */
  }
  
  .overlay a {
    margin-bottom: 0.2rem;
    padding: 1.35rem 0;
    text-decoration: none;
    font-size: 0.95rem;
    color: #000;
    display: block;
    transition: 0.3s;
  }
  

.menu-item-licht {
    padding-top: 1.36rem !important;
  } 
  /*
  .menu-item-home {
    margin: 0.18rem 0 0.18rem !important;
  } 

  .menu-item-klaenge {
    margin:  0.18rem 0 0.2rem !important;
  }  */



  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay {overflow-y: auto;}
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
  
  .footer {
    font-size:30px;
    cursor:pointer;
  } 

  .menu-item {
    background-color: white;
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }


   .menu-item-bottom {
    color: white !important;
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    height: 30px;
    padding-top: 26% !important;
   }

   .header_m {    
    color: black;
    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1.35rem 0;
}

.header_kontakt_m {
  margin-top: 4.2em;
}

.header_licht_m {
/* padding: 1.35rem; */
margin-top: 3.2em;
z-index: 1;
}

.header_klaenge_m {
  margin-top: 4.2em;
  color: black;
  background-color: white;
  position: fixed;
  left: 0;
  width: 100%; 
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 1.37rem 0;
  /* test */
  z-index: 1; 
}