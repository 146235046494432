.karussel_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    display: flex;
    width: 90%; 
    margin: 0 auto;
    justify-content: center;
    height: 100%;
    min-height: 212.7px;
}



/* 1520 px breit ist der Umbruchpunkt, an dem man nur noch 2 Tracks braucht */