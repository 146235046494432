@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

html, body {
  margin: 0;
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(9.8px + 2.35 * ((6.5vw - 8px) / 24));
}

@media (max-width: 700px){
  html, body {
    margin: 0;
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14.2px;
  }
}

header {
  font-family: 'Libre Baskerville';
}
