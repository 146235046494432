.header_d {
    padding: 1.1%;
    background: #fff;
    font-size: 30px;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_white_d {
  padding: 8.6px;
  background: #c2c4bf;
  font-size: 30px;
  width: 100%;
  z-index: 2;
  position: fixed;
  left: 0;
}

.footerImage_m_d {
    margin: 0 25px;
    width: 4rem;
    height: 4rem;
}



div > .header_d {
    z-index: 2;
    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  
  .overlay_desktop {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    /* safari fallback */
    background-color: rgb(50, 45, 45);
    background-color: rgb(11, 10, 10,0.7);
    
    overflow-y: hidden;
    overflow: scroll;
    transition: 0.5s;
    /* padding: 2.5% 0 5% ; */
  }

  .overlay_d_0 {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color:#2c2d2b;
    overflow-y: hidden;
    transition: 0.5s;
  }

  .overlay_desktop a {
    margin: 1% 0;
    padding: 15px 0;
    text-decoration: none;
    font-size: 1.5rem;
    color: #000;
    display: block;
    transition: 0.3s;
  }
  
  .overlay_d a:hover, .overlay_d a:focus {
    color: #f1f1f1;
  }
  
  .overlay_d .closebtn_d {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay_d {overflow-y: auto;}
    .overlay_d a {font-size: 20px}
    .overlay_d .closebtn_d {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
  
  .footer_d {
    font-size:30px;
    cursor:pointer;
   } 

   .menu-item_d {
     background-color: white;
     font-family: 'Libre Baskerville', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
       margin: 0;
   }

   .header_m_d {    
    color: black;

    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1.35rem 0;
}

.header_kontakt_m_d {
  margin-top: 5.5rem;
  padding: 1.37rem 0;
}

.header_licht_m_d {
  padding-top: 15px;
  margin-top: 3.8rem;
}

/* impressumStyles */
.impressum {
  line-height: 1.5;
}

.impressumStyles {
  /* padding-top: 0.3%;
  padding-bottom: 3%;
  color: white; */
  padding-bottom: 3.4%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}
/*
@media screen and (max-width: 1800px) and (min-height: 800) {
  .impressumStyles {
    padding-top: 3%;
    padding-bottom: 3%;
    color: white;
  }
}

@media screen and (max-width: 1500px) and (min-height: 800) {
  .impressumStyles {
    padding-top: 6%;
    padding-bottom: 6%;
    color: white;
  }
}

@media screen and (max-width: 1200px) and (min-height: 800) {
  .impressumStyles {
    padding-top: 9%;
    padding-bottom: 9%;
    color: white;
  }
}

@media screen and (max-width: 900px) {
  .impressumStyles {
    padding-top: 10%;
    padding-bottom: 10%;
    color: white;
  }
} */