.kontakt_m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    margin: 5% auto 12%;
    padding: 5% 0 0;
    width: 315px;
    line-height: 1.6;
    font-size: 0.95rem;
    display: flex;
}

.kontakt_m_container {
    background-color: #c2c4bf;
    height: 18rem;
    margin-top: -5.8rem
}

.kontakt_links_m {
    width: 370px;
    padding-right: 25px;
    font-size: 1rem;
}

.kontakt_rechts_m {
    border-left: 1px black solid;
    width: 370px;
    font-size: 1rem;
    padding-left: 25px;
}

 .kontakt_block_m {
    display: block;
    text-decoration: none;
    color: white;
} 

.kontakt_block_m:active {
    display: block;
    text-decoration: none;
    color: white;
}

.kontakt_block_m:focus {
    display: block;
    text-decoration: none;
    color: white;
}

.kontakt_block_m:visited {
    display: block;
    text-decoration: none;
    color: white;
}

