.header_d {
    padding: 1.1%;
    background: #fff;
    font-size: 30px;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_white_d {
  padding: 8.6px;
  background: #c2c4bf;
  font-size: 30px;
  width: 100%;
  z-index: 0;
  position: fixed;
  left: 0;
}

.footerImage_m_d {
    margin: 0 25px;
    width: 4rem;
    height: 4rem;
}



div > .header_d {
    z-index: 0;
    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  
  .overlay_d {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: rgb(11, 10, 10,0.7);
    
    overflow-y: hidden;
    overflow: scroll;
    transition: 0.5s;
    padding-bottom: 5%;
  }

  .overlay_d_0 {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    background-color:#2c2d2b;
    overflow-y: hidden;
    transition: 0.5s;
  }
  
  .overlay-content_d {
    margin-top: 5.4rem;
  }
  


  .overlay_d a {
    margin: 1% 0;
    padding: 15px 0;
    text-decoration: none;
    font-size: 1.5rem;
    color: #000;
    display: block;
    transition: 0.3s;
  }
  
  .overlay_d a:hover, .overlay_d a:focus {
    color: #f1f1f1;
  }
  
  .overlay_d .closebtn_d {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay_d {overflow-y: auto;}
    .overlay_d a {font-size: 20px}
    .overlay_d .closebtn_d {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
  
  .footer_d {
    font-size:30px;
    cursor:pointer;
   } 

   .menu-item_d {
     background-color: white;
     font-family: 'Libre Baskerville', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
    margin: 0;
   }

   .header_m_d {    
    color: black;

    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1.35rem 0;
}

.header_kontakt_m_d {
  margin-top: 5.5rem;
  padding: 1.37rem 0;
}

.header_licht_m_d {
  padding-top: 15px;
  margin-top: 3.8rem;
}

.ende {
  padding-bottom: 1rem;
}

.impressumStyles_m {
  padding-top: 0.3%;
  padding-bottom: 3%;
  color: white;
}