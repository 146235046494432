.klaenge_column_m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    display: flex;
    flex-direction: column;
    width: 98%; 
    margin: 0 auto;
    justify-content: center;
    min-height: 212.7px;
    padding-top: 9rem;
}
/* im zweifel wieder nach oben schieben - klaenge_column_m */ 
.klaenge_space {
    padding-top: 9rem;
    width: 100vw;
    background: #c2c4bf;
    position: fixed;
    z-index: 0;
}

.full {
    width: 100%;
}
