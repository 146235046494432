

.owncontainer {
    padding: 68vh 0 0;
    min-width: 500px;
    max-width: 2200px;
    margin: 0 auto;
    min-height: 212.7px;
    
  }


.lichtcontainer {
  padding: 68vh 0;
  min-width: 500px;
  max-width: 2500px;
  margin: 0 auto;
  min-height: 212.7px;
}

/* @media (max-width: 905px) { */
@media (max-width: 1168px) {
  .lichtcontainer {
      width: 100%;
      /* overflow-y: scroll; */
      min-height: 0px;
      max-height: 100px;
      margin: 0 auto -10%;
  }
}

@media (max-width: 800px) { 
  .lichtcontainer {
      width: 100%;
      /* overflow-y: scroll; */
      min-height: 0px;
      max-height: 200px;
      margin: 0 auto -20%;
  }
}

@media (max-width: 700px) { 
  .lichtcontainer {
      width: 100%;
      /* overflow-y: scroll; */
      min-height: 0px;
      max-height: 100px;
   
  }
}

@media (max-width: 500px) { 
    .lichtcontainer {
        width: 100%;
        overflow-y: scroll;
        min-height: 0px;
        max-height: 100px;
        margin: 0 auto -10%;
    }
  }