.logo {
    min-width:175px;
    width: 11%;
    padding-bottom: 2.5rem;
}

.dot {
    font-size: 0.5rem;
    margin: auto 0;
    padding: 0 0.5rem;
}

.subtext {
    width: 450px;
    margin: 0.7rem auto;
    /* test */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
}

.maintext {
    /* min-width: 595px; */
    max-width: 1200px;
    margin: 0 auto;
    font-size: 1rem;
}


.seperator {
    width: 10px;
    padding: 0 0.9%;
}

.seperator_left {
    padding-left: 0.4%;
}