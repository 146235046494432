* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100vmin;
  letter-spacing: 0.4px;
}

.text-title {
  margin-top: 15px;
  margin-bottom: 10px;
  line-height: 30px;
}

.App-header {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: calc(1px + 2vmin); */
  font-size: 0.8rem;
  color: rgb(0, 0, 0);
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.backgroundimg {
  background-image: url('./Bilder/lichtbild2.jpg');
  background-size: cover;
  background-position: center; 
  min-height: 100%;
  overflow-y: hidden;
  margin-top: -2.3%;
}

/* hier wenn es kleiner wird als 1010px */
/* wichtig zum scrollen */
@media (max-width: 1010px){
  .backgroundimg {
    background-image: url('./Bilder/lichtbild2.jpg');
    background-size: cover;
    background-position: center bottom; 
    min-height: 100%;
    overflow-y: hidden;
  }
}


.body {
  background-color: #c2c4bf;
  letter-spacing: 0.3px;
  margin: 0;
}

@media (max-width: 480px){
.body {
  background-color: #c2c4bf !important;
}
}

.gross {
  min-height: 700px;
  height: 100vh;
}