

.licht_container_II_m {
    padding: 1em 0.5em;
    letter-spacing: 0.9px;
    /* margin: 0 auto; */
    color: black;
} 

.licht_ab_m {
    /* line-height: 0;
    padding-top: 34%;
    font-size: 1rem; */

    padding-top: 34%;
    margin-block-start: 1.8rem;
    margin-block-end: 1.3rem;
    color: black;
    line-height: 1.6;
    font-size: 1.3rem;
}

.licht_a_m {
    padding-top: 28%;
    margin-block-end: 0;
    font-size: 1.25rem;
}
 .licht_b_m {
    /* margin-block-start: 1.8rem;
    margin-block-end: 1.3rem; */
    color: black;
    line-height: 1.6;
    font-size: 1.25rem;
}


.licht_m {
    margin-block-start: 1.3rem;
    /* margin-block-end: 1.3rem; */
    color: black;
    line-height: 1.6;
    font-size: 0.95rem;
    letter-spacing: 0.3px;
}

.licht_space {
    padding-top: 7rem;
    width: 100vw;
    background: #c2c4bf;
    position: fixed;
    z-index: 0;
}