/* FS xxx */
.kontakt {
    /* test */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    font-size: 0.8rem;
    margin: 0 -10rem;
    padding: 0 29rem;
    line-height: 1.6;
}

.kontakt_links {
    min-width: 300px;
    margin-top: 1rem;
    margin-left: 6rem;
    /* margin-right: 2rem; */
    letter-spacing: 0.5px;
}

/* FS xxx */
.kontakt_rechts {
    min-width: 300px;
    margin-top: 1rem;
    /* margin-left: 2rem; */
    margin-right: 6rem;
    height: 100%;
    min-height: 130px;
    max-height: 200px;
    letter-spacing: 0.5px;
}

.cluster_1 {
    margin-bottom: 4%;
}

.kontakt_block {
    display: block;
    text-decoration: none;
    color: black;
}

.kontakt_block:hover {
    display: block;
    text-decoration: none;
    color: rgb(147, 137, 137);
}

.kontakt_block:active {
    display: block;
    text-decoration: none;
    color: black;
}
