

@media (max-width: 905px) {
    .klang_m {
        color: black;
        margin-left: 5px;
        margin-right: 5px;
    
            /* test */
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 4.5% 0;
    }
}

.btn-accent_m:hover {
    color: #000;
    text-decoration: none;
}

.klang_title_m {
    /* font-size: 1.3rem; */
    /* font-size: 1rem; */
    width: 100%;
}

.klang_interpret_m {
    /* font-size: 1.2rem; */
    /* font-size: 1rem; */
    margin-bottom: 0.4rem;
    width: 100%;
}

.klang_btn_m {
color: white;
/* font-size: 1rem; */
}

.klang_text_m {
    margin-top: 1%;
    margin-bottom: 2.6%;
    height: 100%;
    max-width: 380px;
    width: 95%;
    line-height: 1.6;
    /* font-size: 1rem; */
}
