/* test rotation */
@media screen and (min-width: 320px) and (max-width: 767px) and (max-height: 600px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    /* transform-origin: left top; */
    transform-origin:left top;
    width: 100vh;
    height: 100vh; 
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}



.App_m {
  text-align: center;
}

.App-header_m {
  /* flex-direction: row;
  align-items: center;
  justify-content: center; */
  font-size: 0.95rem;
  color: white;
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body_m {
  background-color: #c2c4bf;
  letter-spacing: 0.3px;
  line-height: 2rem;
  margin: 0;
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.size_m {

  /* für Kontakt */
  min-height: 92vh;
  height: 100%;
  background-color: #c2c4bf;
  display: flex;
  align-content: space-around;
/* margin-bottom: -13%; */

}  

.fixedcontainer_m {
height: 90vh;
width: 100vh;
}
