
.border_m {
    border-left: 1px black solid;
    /* margin: 0 10%;    */
    padding-left: 5%;
    text-align: left;
    min-width: 120px; 
    line-height: 1.6;
}

.no_border_m {
     /* padding: 0 10%;   */
    padding-right: 5%;
    text-align: right;
    /* min-width: 130px; */
    /* padding-left: 11%; */
    line-height: 1..6;
}

.logo_m {
    min-width:150px;
    width: 11%;
    margin: 3.2vh auto;
}

.dot_m {
    font-size: 0.5rem;
    margin: auto 0;
    padding: 0 0.5rem;
}

.subtext_m {
     /* width: 320px; */
    /* margin: 15px auto; */

    /* display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox; */

    color: black;
    font-size: 0.95rem;

    padding-top: 15px;
    padding-bottom: 15px;

    display: flex;
    flex-direction: row;
    justify-content: center; 
}
div > .h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}
.maintext_m {
    width: 21rem;
    margin: 0 auto;
     display: flex;
    flex-direction: row; 
    font-size: 0.95rem;
    padding-left: 8vw;
    line-height: 1.6;
}
