@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap);
html, body {
  margin: 0;
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(9.8px + 2.35 * ((6.5vw - 8px) / 24));
}

@media (max-width: 700px){
  html, body {
    margin: 0;
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14.2px;
  }
}

header {
  font-family: 'Libre Baskerville';
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100vmin;
  letter-spacing: 0.4px;
}

.text-title {
  margin-top: 15px;
  margin-bottom: 10px;
  line-height: 30px;
}

.App-header {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: calc(1px + 2vmin); */
  font-size: 0.8rem;
  color: rgb(0, 0, 0);
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.backgroundimg {
  background-image: url(/static/media/lichtbild2.4f592b34.jpg);
  background-size: cover;
  background-position: center; 
  min-height: 100%;
  overflow-y: hidden;
  margin-top: -2.3%;
}

/* hier wenn es kleiner wird als 1010px */
/* wichtig zum scrollen */
@media (max-width: 1010px){
  .backgroundimg {
    background-image: url(/static/media/lichtbild2.4f592b34.jpg);
    background-size: cover;
    background-position: center bottom; 
    min-height: 100%;
    overflow-y: hidden;
  }
}


.body {
  background-color: #c2c4bf;
  letter-spacing: 0.3px;
  margin: 0;
}

@media (max-width: 480px){
.body {
  background-color: #c2c4bf !important;
}
}

.gross {
  min-height: 700px;
  height: 100vh;
}
.carousel-control-prev-icon {
    background-image: url(/static/media/pfeilblacklinks.63fbd49c.png);
}

.carousel-control-next-icon {
    background-image: url(/static/media/pfeilblackrechts.3dfaf464.png);
}


.owncontainer {
    padding: 68vh 0 0;
    min-width: 500px;
    max-width: 2200px;
    margin: 0 auto;
    min-height: 212.7px;
    
  }


.lichtcontainer {
  padding: 68vh 0;
  min-width: 500px;
  max-width: 2500px;
  margin: 0 auto;
  min-height: 212.7px;
}

/* @media (max-width: 905px) { */
@media (max-width: 1168px) {
  .lichtcontainer {
      width: 100%;
      /* overflow-y: scroll; */
      min-height: 0px;
      max-height: 100px;
      margin: 0 auto -10%;
  }
}

@media (max-width: 800px) { 
  .lichtcontainer {
      width: 100%;
      /* overflow-y: scroll; */
      min-height: 0px;
      max-height: 200px;
      margin: 0 auto -20%;
  }
}

@media (max-width: 700px) { 
  .lichtcontainer {
      width: 100%;
      /* overflow-y: scroll; */
      min-height: 0px;
      max-height: 100px;
   
  }
}

@media (max-width: 500px) { 
    .lichtcontainer {
        width: 100%;
        overflow-y: scroll;
        min-height: 0px;
        max-height: 100px;
        margin: 0 auto -10%;
    }
  }
.logo {
    min-width:175px;
    width: 11%;
    padding-bottom: 2.5rem;
}

.dot {
    font-size: 0.5rem;
    margin: auto 0;
    padding: 0 0.5rem;
}

.subtext {
    width: 450px;
    margin: 0.7rem auto;
    /* test */

    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
}

.maintext {
    /* min-width: 595px; */
    max-width: 1200px;
    margin: 0 auto;
    font-size: 1rem;
}


.seperator {
    width: 10px;
    padding: 0 0.9%;
}

.seperator_left {
    padding-left: 0.4%;
}
/* FS xxx */
.licht {
    margin-top: 1.8em;
    margin-bottom: 1.3em;
    color: black;
    line-height: 1.6;
    font-size: 0.8rem;
}

/* FS xxx */
.licht_a {
    margin: 0;
    color: black;
    line-height: 1.6;
    font-size: 0.8rem;
}    

/* FS xxx */
.licht_b {
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    color: black;
    line-height: 1.6;
    font-size: 0.8rem;
}

/* 1100px sind die grenze  */
@media (max-width: 1050px) {
    .licht_a {
        display: inline;
        -webkit-margin-after: 0;
                margin-block-end: 0;
    }
     .licht_b {
        display: inline;
    }
    .licht_ab {
        line-height: 0;
    }
    .licht_c {
        display: inline;
    }
}

.licht_container {
    padding: 0 6.5%;
    letter-spacing: 0.5px;
    max-width: 2100px;
    margin: 0 auto;
}

@media (max-width: 670px){
    .licht_container {
        padding: 0 9%;
        letter-spacing: 0.5px;
        max-width: 1850px;
        margin: 0 auto;
        margin: 0 auto -62%;
    }
}
.karussel_row {

    display: flex;
    width: 90%; 
    margin: 0 auto;
    justify-content: center;
    height: 100%;
    min-height: 212.7px;
}



/* 1520 px breit ist der Umbruchpunkt, an dem man nur noch 2 Tracks braucht */

.klang {
    color: black;
    width: 510px;
    margin-left: 5px;
    margin-right: 5px;

        /* test */

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 530px;
    line-height: 1.6;
}

@media (max-width: 1520px) {
    .klang {
        color: black;
        width: 45%;
        margin-left: 5px;
        margin-right: 5px;
    
            /* test */
    
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 530px;
    }
}

@media (max-width: 905px) {
    .klang {
        color: black;
        margin-left: 5px;
        margin-right: 5px;
    
            /* test */
    
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.btn-accent:hover {
    color: black;
    text-decoration: none;
}

/* FS xxx */
.klang_title {
    font-size: 0.8rem;
    width: 100%;
}

/* FS xxx */
.klang_interpret{
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
    width: 100%;
}

/* FS xxx */
.klang_btn {
    color: black;
    font-size: 0.8rem;
}

.klang_btn:hover {
    /* color: rgb(0, 0, 0,80); */
    color: rgb(147, 137, 137);
    font-size: 0.8rem;
}

/* FS xxx */
.klang_text {
    margin-bottom: 0.8rem;
    width: 500px;
    line-height: 1.6;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
}

@media (max-width: 1520px) {
    .klang_text {
        margin-bottom: 0.8rem;
        width: 100%;
        max-width: 500px;
        line-height: 1.6;
        letter-spacing: 0.5px;
    }
}

/* FS xxx */
@media (max-width: 905px) {
    .klang_text {
        margin-bottom: 0.8rem;
        max-width: 550px;
        line-height: 1.6;
        font-size: 0.8rem;
        letter-spacing: 0.5px;
    } 
}
/* FS xxx */
.kontakt {
    /* test */

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    font-size: 0.8rem;
    margin: 0 -10rem;
    padding: 0 29rem;
    line-height: 1.6;
}

.kontakt_links {
    min-width: 300px;
    margin-top: 1rem;
    margin-left: 6rem;
    /* margin-right: 2rem; */
    letter-spacing: 0.5px;
}

/* FS xxx */
.kontakt_rechts {
    min-width: 300px;
    margin-top: 1rem;
    /* margin-left: 2rem; */
    margin-right: 6rem;
    height: 100%;
    min-height: 130px;
    max-height: 200px;
    letter-spacing: 0.5px;
}

.cluster_1 {
    margin-bottom: 4%;
}

.kontakt_block {
    display: block;
    text-decoration: none;
    color: black;
}

.kontakt_block:hover {
    display: block;
    text-decoration: none;
    color: rgb(147, 137, 137);
}

.kontakt_block:active {
    display: block;
    text-decoration: none;
    color: black;
}


    div > .footer {
    z-index: 2;
    background-color: grey;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; 

        /* test um flexbox auch auf anderen devices zu ermöglichen */

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: .4rem 1.9rem;
    font-family: 'Libre Baskerville';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.footer_container{

        /* test */

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    padding-left: 7.3rem;
}

div > .footerString {
    line-height: 136%;
}

/* FS xxx */
div > .footerElement { 
    color: #b4afab;
    margin-bottom: 0;
    font-size: 0.8rem;
    width: 100%;
    margin: 0.4em 0.38em;
}
div > .footerElementButton:hover {
    color: #FFFFFF;
    cursor: pointer;                         
}

div > .footerElementButton:active { color: #FFFFFF }

div > .footerElementButtonActive {  
    color: #FFFFFF;
    cursor: pointer;
}

.footerImage {

    margin: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
}

/* FS xxx */
.footerCenter {
    color: #b4afab;
    margin-bottom: 0;
    font-size: 0.8rem;
    width: 100%;
    margin: 0.35rem;
}

/* FS xxx */
.footerImpressum {
    width: 100px;
    color: #b4afab;
    margin-bottom: 0;
    font-size: 0.8rem;
    margin: 0.25rem 0.15rem;
    padding-right: 0.7rem;
}
.header_d {
    padding: 1.1%;
    background: #fff;
    font-size: 30px;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_white_d {
  padding: 8.6px;
  background: #c2c4bf;
  font-size: 30px;
  width: 100%;
  z-index: 2;
  position: fixed;
  left: 0;
}

.footerImage_m_d {
    margin: 0 25px;
    width: 4rem;
    height: 4rem;
}



div > .header_d {
    z-index: 2;
    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  
  .overlay_desktop {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    /* safari fallback */
    background-color: rgb(50, 45, 45);
    background-color: rgb(11, 10, 10,0.7);
    
    overflow-y: hidden;
    overflow: scroll;
    transition: 0.5s;
    /* padding: 2.5% 0 5% ; */
  }

  .overlay_d_0 {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color:#2c2d2b;
    overflow-y: hidden;
    transition: 0.5s;
  }

  .overlay_desktop a {
    margin: 1% 0;
    padding: 15px 0;
    text-decoration: none;
    font-size: 1.5rem;
    color: #000;
    display: block;
    transition: 0.3s;
  }
  
  .overlay_d a:hover, .overlay_d a:focus {
    color: #f1f1f1;
  }
  
  .overlay_d .closebtn_d {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay_d {overflow-y: auto;}
    .overlay_d a {font-size: 20px}
    .overlay_d .closebtn_d {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
  
  .footer_d {
    font-size:30px;
    cursor:pointer;
   } 

   .menu-item_d {
     background-color: white;
     font-family: 'Libre Baskerville', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
       margin: 0;
   }

   .header_m_d {    
    color: black;

    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1.35rem 0;
}

.header_kontakt_m_d {
  margin-top: 5.5rem;
  padding: 1.37rem 0;
}

.header_licht_m_d {
  padding-top: 15px;
  margin-top: 3.8rem;
}

/* impressumStyles */
.impressum {
  line-height: 1.5;
}

.impressumStyles {
  /* padding-top: 0.3%;
  padding-bottom: 3%;
  color: white; */
  padding-bottom: 3.4%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  
    position: absolute;
    top: 50%;
    left: 50%;

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);
}
/*
@media screen and (max-width: 1800px) and (min-height: 800) {
  .impressumStyles {
    padding-top: 3%;
    padding-bottom: 3%;
    color: white;
  }
}

@media screen and (max-width: 1500px) and (min-height: 800) {
  .impressumStyles {
    padding-top: 6%;
    padding-bottom: 6%;
    color: white;
  }
}

@media screen and (max-width: 1200px) and (min-height: 800) {
  .impressumStyles {
    padding-top: 9%;
    padding-bottom: 9%;
    color: white;
  }
}

@media screen and (max-width: 900px) {
  .impressumStyles {
    padding-top: 10%;
    padding-bottom: 10%;
    color: white;
  }
} */
 .header {
    padding: 1.1%;
    background: grey;
    font-size: 30px;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_white {
  background: grey;
  font-size: 30px;
  width: 100%;
  z-index: 3;
  position: fixed;
  left: 0;
  padding: 1.1%;
}

.footerImage_m {
    margin: auto 25px 4px;
    width: 2.4rem;
    height: 2.4rem;
}


div > .header {
    z-index: 2;
    background-color: grey;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  
  .overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color:grey;
    overflow-y: hidden;
    transition: 0.5s;
  }
  
  .overlay-content {
    position: relative;
    top: 30%;
    /* width: 100%;
    height: 75%;
    text-align: center; */
  }
  
  .overlay a {
    margin-bottom: 0.2rem;
    padding: 1.35rem 0;
    text-decoration: none;
    font-size: 0.95rem;
    color: #000;
    display: block;
    transition: 0.3s;
  }
  

.menu-item-licht {
    padding-top: 1.36rem !important;
  } 
  /*
  .menu-item-home {
    margin: 0.18rem 0 0.18rem !important;
  } 

  .menu-item-klaenge {
    margin:  0.18rem 0 0.2rem !important;
  }  */



  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay {overflow-y: auto;}
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
  
  .footer {
    font-size:30px;
    cursor:pointer;
  } 

  .menu-item {
    background-color: white;
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }


   .menu-item-bottom {
    color: white !important;
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    height: 30px;
    padding-top: 26% !important;
   }

   .header_m {    
    color: black;
    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1.35rem 0;
}

.header_kontakt_m {
  margin-top: 4.2em;
}

.header_licht_m {
/* padding: 1.35rem; */
margin-top: 3.2em;
z-index: 1;
}

.header_klaenge_m {
  margin-top: 4.2em;
  color: black;
  background-color: white;
  position: fixed;
  left: 0;
  width: 100%; 
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 1.37rem 0;
  /* test */
  z-index: 1; 
}

.border_m {
    border-left: 1px black solid;
    /* margin: 0 10%;    */
    padding-left: 5%;
    text-align: left;
    min-width: 120px; 
    line-height: 1.6;
}

.no_border_m {
     /* padding: 0 10%;   */
    padding-right: 5%;
    text-align: right;
    /* min-width: 130px; */
    /* padding-left: 11%; */
    line-height: 1..6;
}

.logo_m {
    min-width:150px;
    width: 11%;
    margin: 3.2vh auto;
}

.dot_m {
    font-size: 0.5rem;
    margin: auto 0;
    padding: 0 0.5rem;
}

.subtext_m {
     /* width: 320px; */
    /* margin: 15px auto; */

    /* display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox; */

    color: black;
    font-size: 0.95rem;

    padding-top: 15px;
    padding-bottom: 15px;

    display: flex;
    flex-direction: row;
    justify-content: center; 
}
div > .h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}
.maintext_m {
    width: 21rem;
    margin: 0 auto;
     display: flex;
    flex-direction: row; 
    font-size: 0.95rem;
    padding-left: 8vw;
    line-height: 1.6;
}


.owncontainer_m {
    margin: 0 auto;
    min-height: 212.7px;
  }


.lichtcontainer_m {
  margin: 0 auto;
  min-height: 412.7px;
}



@media (max-width: 800px) { 
  .lichtcontainer_m {
      width: 100%;
      overflow-y: scroll;
      min-height: 0px;
      max-height: 200px;
      margin: 0 auto -20%;
  }
}

@media (max-width: 700px) { 
  .lichtcontainer_m {
      width: 100%;
      overflow-y: scroll;
      min-height: 0px;
      max-height: 100px;
      margin: 0 auto -30%;
  }
}

@media (max-width: 600px) { 
    .lichtcontainer_m {
        width: 100%;
        /* overflow-y: scroll; */

        min-height: 650px;
        margin: 0 auto;
    }
  }


.homesub_m {
     /* height: 100%;
     max-height: 280px;  */
    /* max-height: 320px; */
    background-color: #c2c4bf;
    margin-top: -23.8vh;
}  
.kontakt_m {
    display: -ms-flexbox;

    margin: 5% auto 12%;
    padding: 5% 0 0;
    width: 315px;
    line-height: 1.6;
    font-size: 0.95rem;
    display: flex;
}

.kontakt_m_container {
    background-color: #c2c4bf;
    height: 18rem;
    margin-top: -5.8rem
}

.kontakt_links_m {
    width: 370px;
    padding-right: 25px;
    font-size: 1rem;
}

.kontakt_rechts_m {
    border-left: 1px black solid;
    width: 370px;
    font-size: 1rem;
    padding-left: 25px;
}

 .kontakt_block_m {
    display: block;
    text-decoration: none;
    color: white;
} 

.kontakt_block_m:active {
    display: block;
    text-decoration: none;
    color: white;
}

.kontakt_block_m:focus {
    display: block;
    text-decoration: none;
    color: white;
}

.kontakt_block_m:visited {
    display: block;
    text-decoration: none;
    color: white;
}




.licht_container_II_m {
    padding: 1em 0.5em;
    letter-spacing: 0.9px;
    /* margin: 0 auto; */
    color: black;
} 

.licht_ab_m {
    /* line-height: 0;
    padding-top: 34%;
    font-size: 1rem; */

    padding-top: 34%;
    -webkit-margin-before: 1.8rem;
            margin-block-start: 1.8rem;
    -webkit-margin-after: 1.3rem;
            margin-block-end: 1.3rem;
    color: black;
    line-height: 1.6;
    font-size: 1.3rem;
}

.licht_a_m {
    padding-top: 28%;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    font-size: 1.25rem;
}
 .licht_b_m {
    /* margin-block-start: 1.8rem;
    margin-block-end: 1.3rem; */
    color: black;
    line-height: 1.6;
    font-size: 1.25rem;
}


.licht_m {
    -webkit-margin-before: 1.3rem;
            margin-block-start: 1.3rem;
    /* margin-block-end: 1.3rem; */
    color: black;
    line-height: 1.6;
    font-size: 0.95rem;
    letter-spacing: 0.3px;
}

.licht_space {
    padding-top: 7rem;
    width: 100vw;
    background: #c2c4bf;
    position: fixed;
    z-index: 0;
}
.klaenge_column_m {

    display: flex;
    flex-direction: column;
    width: 98%; 
    margin: 0 auto;
    justify-content: center;
    min-height: 212.7px;
    padding-top: 9rem;
}
/* im zweifel wieder nach oben schieben - klaenge_column_m */ 
.klaenge_space {
    padding-top: 9rem;
    width: 100vw;
    background: #c2c4bf;
    position: fixed;
    z-index: 0;
}

.full {
    width: 100%;
}



@media (max-width: 905px) {
    .klang_m {
        color: black;
        margin-left: 5px;
        margin-right: 5px;
    
            /* test */
    
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 4.5% 0;
    }
}

.btn-accent_m:hover {
    color: #000;
    text-decoration: none;
}

.klang_title_m {
    /* font-size: 1.3rem; */
    /* font-size: 1rem; */
    width: 100%;
}

.klang_interpret_m {
    /* font-size: 1.2rem; */
    /* font-size: 1rem; */
    margin-bottom: 0.4rem;
    width: 100%;
}

.klang_btn_m {
color: white;
/* font-size: 1rem; */
}

.klang_text_m {
    margin-top: 1%;
    margin-bottom: 2.6%;
    height: 100%;
    max-width: 380px;
    width: 95%;
    line-height: 1.6;
    /* font-size: 1rem; */
}

.header_d {
    padding: 1.1%;
    background: #fff;
    font-size: 30px;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_white_d {
  padding: 8.6px;
  background: #c2c4bf;
  font-size: 30px;
  width: 100%;
  z-index: 0;
  position: fixed;
  left: 0;
}

.footerImage_m_d {
    margin: 0 25px;
    width: 4rem;
    height: 4rem;
}



div > .header_d {
    z-index: 0;
    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

  
  .overlay_d {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: rgb(11, 10, 10,0.7);
    
    overflow-y: hidden;
    overflow: scroll;
    transition: 0.5s;
    padding-bottom: 5%;
  }

  .overlay_d_0 {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    background-color:#2c2d2b;
    overflow-y: hidden;
    transition: 0.5s;
  }
  
  .overlay-content_d {
    margin-top: 5.4rem;
  }
  


  .overlay_d a {
    margin: 1% 0;
    padding: 15px 0;
    text-decoration: none;
    font-size: 1.5rem;
    color: #000;
    display: block;
    transition: 0.3s;
  }
  
  .overlay_d a:hover, .overlay_d a:focus {
    color: #f1f1f1;
  }
  
  .overlay_d .closebtn_d {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay_d {overflow-y: auto;}
    .overlay_d a {font-size: 20px}
    .overlay_d .closebtn_d {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
  
  .footer_d {
    font-size:30px;
    cursor:pointer;
   } 

   .menu-item_d {
     background-color: white;
     font-family: 'Libre Baskerville', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
    margin: 0;
   }

   .header_m_d {    
    color: black;

    background-color: white;
    position: fixed;
    left: 0;
    width: 100%; 
    font-family: 'Libre Baskerville', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1.35rem 0;
}

.header_kontakt_m_d {
  margin-top: 5.5rem;
  padding: 1.37rem 0;
}

.header_licht_m_d {
  padding-top: 15px;
  margin-top: 3.8rem;
}

.ende {
  padding-bottom: 1rem;
}

.impressumStyles_m {
  padding-top: 0.3%;
  padding-bottom: 3%;
  color: white;
}
/* 60& Iphone se */
.backgroundimg_m {
    background-image: url(/static/media/lichtbild2.4f592b34.jpg);
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat;
    height: 92%;
    /* margin-top: -4%; */
  }

  .backgroundimg_kontakt_m {
    background-image: url(/static/media/lichtbild2.4f592b34.jpg);
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    height: 63vh;
    /* margin-top: 1.4rem; */
    /* padding-top: 5rem; */
  }


  .background_container {
    height: 84.5vh;
  }

   .background_container_kontakt {
    /* height: 31.55rem; */
    /* margin-top: 13%;
    margin-bottom: -13%; */
    padding-top: 4.8rem;
  }
  


/* test rotation */
@media screen and (min-width: 320px) and (max-width: 767px) and (max-height: 600px) and (orientation: landscape) {
  html {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    /* transform-origin: left top; */
    -webkit-transform-origin:left top;
            transform-origin:left top;
    width: 100vh;
    height: 100vh; 
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}



.App_m {
  text-align: center;
}

.App-header_m {
  /* flex-direction: row;
  align-items: center;
  justify-content: center; */
  font-size: 0.95rem;
  color: white;
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body_m {
  background-color: #c2c4bf;
  letter-spacing: 0.3px;
  line-height: 2rem;
  margin: 0;
  font-family: 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.size_m {

  /* für Kontakt */
  min-height: 92vh;
  height: 100%;
  background-color: #c2c4bf;
  display: flex;
  align-content: space-around;
/* margin-bottom: -13%; */

}  

.fixedcontainer_m {
height: 90vh;
width: 100vh;
}

