/* FS xxx */
.licht {
    margin-top: 1.8em;
    margin-bottom: 1.3em;
    color: black;
    line-height: 1.6;
    font-size: 0.8rem;
}

/* FS xxx */
.licht_a {
    margin: 0;
    color: black;
    line-height: 1.6;
    font-size: 0.8rem;
}    

/* FS xxx */
.licht_b {
    margin-block-end: 1em;
    color: black;
    line-height: 1.6;
    font-size: 0.8rem;
}

/* 1100px sind die grenze  */
@media (max-width: 1050px) {
    .licht_a {
        display: inline;
        margin-block-end: 0;
    }
     .licht_b {
        display: inline;
    }
    .licht_ab {
        line-height: 0;
    }
    .licht_c {
        display: inline;
    }
}

.licht_container {
    padding: 0 6.5%;
    letter-spacing: 0.5px;
    max-width: 2100px;
    margin: 0 auto;
}

@media (max-width: 670px){
    .licht_container {
        padding: 0 9%;
        letter-spacing: 0.5px;
        max-width: 1850px;
        margin: 0 auto;
        margin: 0 auto -62%;
    }
}