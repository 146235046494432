
.klang {
    color: black;
    width: 510px;
    margin-left: 5px;
    margin-right: 5px;

        /* test */
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 530px;
    line-height: 1.6;
}

@media (max-width: 1520px) {
    .klang {
        color: black;
        width: 45%;
        margin-left: 5px;
        margin-right: 5px;
    
            /* test */
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 530px;
    }
}

@media (max-width: 905px) {
    .klang {
        color: black;
        margin-left: 5px;
        margin-right: 5px;
    
            /* test */
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.btn-accent:hover {
    color: black;
    text-decoration: none;
}

/* FS xxx */
.klang_title {
    font-size: 0.8rem;
    width: 100%;
}

/* FS xxx */
.klang_interpret{
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
    width: 100%;
}

/* FS xxx */
.klang_btn {
    color: black;
    font-size: 0.8rem;
}

.klang_btn:hover {
    /* color: rgb(0, 0, 0,80); */
    color: rgb(147, 137, 137);
    font-size: 0.8rem;
}

/* FS xxx */
.klang_text {
    margin-bottom: 0.8rem;
    width: 500px;
    line-height: 1.6;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
}

@media (max-width: 1520px) {
    .klang_text {
        margin-bottom: 0.8rem;
        width: 100%;
        max-width: 500px;
        line-height: 1.6;
        letter-spacing: 0.5px;
    }
}

/* FS xxx */
@media (max-width: 905px) {
    .klang_text {
        margin-bottom: 0.8rem;
        max-width: 550px;
        line-height: 1.6;
        font-size: 0.8rem;
        letter-spacing: 0.5px;
    } 
}