
    div > .footer {
    z-index: 2;
    background-color: grey;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%; 

        /* test um flexbox auch auf anderen devices zu ermöglichen */
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: .4rem 1.9rem;
    font-family: 'Libre Baskerville';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.footer_container{

        /* test */
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    padding-left: 7.3rem;
}

div > .footerString {
    line-height: 136%;
}

/* FS xxx */
div > .footerElement { 
    color: #b4afab;
    margin-bottom: 0;
    font-size: 0.8rem;
    width: 100%;
    margin: 0.4em 0.38em;
}
div > .footerElementButton:hover {
    color: #FFFFFF;
    cursor: pointer;                         
}

div > .footerElementButton:active { color: #FFFFFF }

div > .footerElementButtonActive {  
    color: #FFFFFF;
    cursor: pointer;
}

.footerImage {

    margin: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
}

/* FS xxx */
.footerCenter {
    color: #b4afab;
    margin-bottom: 0;
    font-size: 0.8rem;
    width: 100%;
    margin: 0.35rem;
}

/* FS xxx */
.footerImpressum {
    width: 100px;
    color: #b4afab;
    margin-bottom: 0;
    font-size: 0.8rem;
    margin: 0.25rem 0.15rem;
    padding-right: 0.7rem;
}